/*
 * @Date: 2023-11-09 11:28:16
 */
import { OeReportSubType, OeReportType } from "./helper/enum";
import { useUser } from "./helper/useUser";
import { useQuery } from './helper/useQuery';
import { useDevice } from "./helper/useDevice";
import { useOeReport } from "./helper/useOeReport";
import { useRouter } from "./helper/useRouter";
import { useLifeCycle } from "./helper/useLifeCycle";
import { useVisible } from "./helper/useVisible";
import { useDirective, _first_inter_show } from "./helper/useDirective";

const { userInfo } = useUser();
const { initQuery } = useQuery();
const { oeReport, startUsingTimer, stopUsingTimer, dailyLaunchReport, _calculatedPageCount } = useOeReport();
const { setRouter } = useRouter();
const { visible } = useVisible();
const { setGlobalDirective } = useDirective();

const SESSION_AD_CLICK = "HAA_AD_CLICK_SESSION";

export interface IHaaCfg {
    /** DP必要参数对应的key */
    queryMap: QueryMap;
    /** 路由实例 */
    router: any;
    /** 路由首页的name */
    home: string | string[];
    /** 应用实例 */
    app: any;
    /** 页面深度初始值 */
    deepStart?: number;
    /** 首页插屏的spaceKey */
    firstInterSpaceKey?: string;
}

const def = {
  util: () => {
    return { useDevice, useOeReport, useUser, useLifeCycle, useDirective };
  },
  init(cfg: IHaaCfg) {
    /** 如果配置了首页插屏的spaceKey，记录一下 */
    if (cfg.firstInterSpaceKey) _first_inter_show.spaceKey = cfg.firstInterSpaceKey;
    /** 如果配置了页面深度初始值的话，赋值 */
    if (cfg.deepStart) _calculatedPageCount.count = cfg.deepStart - 1;
    /** 设置通用自定义指令 */
    setGlobalDirective(cfg.app);
    /** 保存路由实例，设置路由拦截，进行页面打点上报 */
    setRouter(cfg.router, cfg.home);
    /** 判断是否第一次进入，保存query参数 */
    if (userInfo.isRefresh) {
      // 刷新后，无法立即获取到router，设个延迟
      setTimeout(() => {
        const pageName = cfg.router.currentRoute.value.name;
        oeReport(OeReportType.Action, OeReportSubType.Refresh, pageName);
      }, 1000);
    } else {
      initQuery(cfg.queryMap);
      /** 上报启动 */
      dailyLaunchReport();
      // reportLaunch();
      // oeReport(OeReportType.Action, OeReportSubType.AppOeLaunch);
    }

    /** 初始化时，判断是否有缓存的用户点击广告的信息，如果有，上报广告页停留时长 */
    try {
      const clickInfoStr = sessionStorage.getItem(SESSION_AD_CLICK);
      if (clickInfoStr) {
        const clickInfo = JSON.parse(clickInfoStr);
        const { spaceId, spaceKey, timestamp } = clickInfo;
        const now = new Date().getTime();
        const passTime = now - timestamp + "";
        oeReport(OeReportType.Action, OeReportSubType.BackAfterAdClick, spaceId, spaceKey, passTime, undefined, undefined);
        sessionStorage.setItem(SESSION_AD_CLICK, "");
      }
    } catch (error) {
      // 解析缓存数据失败
    }

    /** 开启停留时长定时器，监听前后台切换 */
    startUsingTimer();
    let displayTime = new Date().getTime();
    window.addEventListener("visibilitychange", () => {
      const isHidden = window.document.hidden;
      if (isHidden) {
        visible.value = false;
        displayTime = new Date().getTime();
        stopUsingTimer();
      } else {
        visible.value = true;
        oeReport(OeReportType.Action, OeReportSubType.AppShow, undefined, undefined, new Date().getTime() - displayTime + "");
        startUsingTimer();
      }
    });
  }
};

export default def;
export const Haa = def;
export { OeReportType, OeReportSubType };
