/*
 * @Date: 2023-11-09 11:28:05
 */
const MobileDetect = require("mobile-detect");

const _BRANDS_OPPO = ["oppo", "realme", "oneplus"];
const _BRANDS_VIVO = ["vivo", "iqoo"];
const _BRANDS_MEIZU = ["meizu"];
const _BRANDS_XIAOMI = ["xiaomi", "redmi"];
const _BRANDS_HUAWEI = ["huawei", "honor"];

const getNormalizedBrandName = function () {
  const str = window.navigator?.userAgent?.toLowerCase();

  if (_BRANDS_OPPO.some((item) => str?.indexOf(item) != -1)) {
    return "oppo";
  }
  if (_BRANDS_VIVO.some((item) => str?.indexOf(item) != -1)) {
    return "vivo";
  }
  if (_BRANDS_MEIZU.some((item) => str?.indexOf(item) != -1)) {
    return "meizu";
  }
  if (_BRANDS_XIAOMI.some((item) => str?.indexOf(item) != -1)) {
    return "xiaomi";
  }
  if (_BRANDS_HUAWEI.some((item) => str?.indexOf(item) != -1)) {
    return "huawei";
  }

  return "unknown";
};

const getNetworkType = () => {
  const ua = window.navigator.userAgent;
  const temp = ua.match(/NetType\/\w+/);
  let networkStr = temp && temp.length > 0 ? temp[0] : "NetType/other";
  networkStr = networkStr.toLowerCase().replace("nettype/", "");
  let networkType = "";
  switch (networkStr) {
    case "wifi":
      networkType = "wifi";
      break;
    case "4g":
      networkType = "4g";
      break;
    case "3g":
      networkType = "3g";
      break;
    case "3gnet":
      networkType = "3g";
      break;
    case "2g":
      networkType = "2g";
      break;
    default:
      networkType = "other";
  }

  return networkType;
};

const md = new MobileDetect(window.navigator.userAgent);

const deviceInfo: DeviceInfo = {
  AppName: APP_NAME,
  Model: md.mobile(),
  OSType: md.os(),
  DeviceType: md.mobile() == null ? "pc" : "phone",
  OsVersion: md.os() == "IOS" ? md.version("iPhone") : md.os() == "AndroidOS" ? md.version("Android") : "",
  Brand: getNormalizedBrandName(),
  netType: getNetworkType()
};

export function useDevice() {
  return { deviceInfo };
}
