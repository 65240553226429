/*
 * @Date: 2023-11-09 11:44:25
 */
import { AesEncode, AesDecode } from "./useEncrypt";
import { getParamsFromUrl } from "./utils";

const QUERY_KEY = "QUERY_KEY";

const query: QueryMap = {
  channel: "",
  subchannel: "",
  key: "",
  source: "",
  request_id: "",
  req_id: ""
};

const setQuery = (q: QueryMap) => {
  Object.assign(query, q);
  sessionStorage.setItem(QUERY_KEY, AesEncode(JSON.stringify(query)));
};

const initQuery = (queryMap: QueryMap): void => {
  const params: AnyObject = getParamsFromUrl(window.location.href);

  // console.error(params,queryMap.key)

  const queryBak: QueryMap = {
    channel: "",
    subchannel: "",
    key: "",
    source: window.location.href.split("?")[1] || "",
    request_id: "",
    req_id: "",
  };
  if (params[queryMap.channel]) queryBak.channel = params[queryMap.channel];
  if (params[queryMap.subchannel]) queryBak.subchannel = params[queryMap.subchannel];
  if (params[queryMap.key]) queryBak.key = params[queryMap.key];
  if (params.request_id) queryBak.request_id = params.request_id;
  if (params.req_id) queryBak.req_id = params.req_id;

  // console.error(queryBak)
  setQuery(queryBak);
};

export function useQuery() {
  if (!query.key) {
    try {
      const sessionAes = sessionStorage.getItem(QUERY_KEY);
      if (!sessionAes) return { query, setQuery, initQuery };
      const sessionStr = AesDecode(sessionAes);
      const session = JSON.parse(sessionStr);
      setQuery(session);

      // console.error(session)
    } catch (error) {
      console.log(error);
    }
  }
  return { query, setQuery, initQuery };
}
