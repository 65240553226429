import { randomString, getParamsFromUrl } from "./utils";

const UUID_KEY = "UUID_KEY";
const SESSION_ID_KEY = "SESSION_ID_KEY";

const userInfo = {
  uuid: localStorage.getItem(UUID_KEY),
  sessionId: sessionStorage.getItem(SESSION_ID_KEY),
  appLaunchId: randomString(32),
  isRefresh: true
};

const createUUID = () => {
  return randomString(12) + new Date().getTime();
};

export function useUser() {
  const params = getParamsFromUrl(window.location.href);
  if (params.uuid) {
    userInfo.uuid = params.uuid;
    localStorage.setItem(UUID_KEY, userInfo.uuid + "");
  }
  if (params.launchid) {
    userInfo.sessionId = params.launchid;
    sessionStorage.setItem(SESSION_ID_KEY, userInfo.sessionId + "");

    userInfo.isRefresh = false;
  }
  if (!userInfo.uuid) {
    /** 获取uuid */
    userInfo.uuid = createUUID();
    localStorage.setItem(UUID_KEY, userInfo.uuid);
  }
  if (!userInfo.sessionId) {
    /** 获取session_id */
    userInfo.sessionId = createUUID();
    sessionStorage.setItem(SESSION_ID_KEY, userInfo.sessionId);

    userInfo.isRefresh = false;
  }
  return { userInfo };
}
