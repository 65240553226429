/** OE上报类型 */
export enum OeReportType {
  /** 关键行为 */
  Action = 1001
}

/** OE上报子类型 */
export enum OeReportSubType {
  /** 点击广告后回来经过的时长 */
  BackAfterAdClick = 30000,
  /** 任何一个广告的点击 */
  AdAllClick = 30001,
  /** 任何一个广告的填充成功 */
  AdAllReqSuccess = 30002,
  /** 任何一个广告的填充成功 */
  AdAllReqSuccess2 = 30003,
  /** 非首页曝光 */
  PageShowNotMainOld = 30004,
  /** 跳转快应用按钮曝光 */
  QappBtnShow = 30005,
  /** 跳转快应用按钮点击 */
  QappBtnClick = 30006,
  /** 浏览器刷新 */
  Refresh = 30007,
  /** 首次互动页、首次首页广告点击 */
  SpecialClick = 30008,

  /** 广告请求 */
  AdReq = 31000,
  /** 广告展示 */
  AdShow = 31001,
  /** 广告点击 */
  AdClick = 31002,
  /** 广告关闭 */
  AdClose = 31003,
  /** 广告请求成功 */
  AdReqSuccess = 31004,
  /** 广告请求失败 */
  AdReqFail = 31005,
  /** 广告销毁 */
  AdDestroy = 31007,

  /** 左上角Back点击 */
  TopLeftBack = 31012,
  /** 返回弹窗点击确认 */
  BackDialogConfirm = 31013,
  /** Tab手动点击上报 */
  TabClick = 31014,
  /** 首页曝光 */
  HomeShow = 31015,
  /** 日启频次 */
  DailyLaunch = 31016,
  /** 单启前台停留时长 */
  Stay = 31017,
  /** 下载 */
  Download = 31018,
  /** 收藏 */
  Collect = 31019,
  /** 点赞 */
  Like = 31020,
  /** 单启页面深度（默认页面前台超1s才算一个） */
  PageDeep = 31021,
  /** 点击下一个 */
  NextClick = 31022,
  /** 点击换一个 */
  OtherClick = 31023,
  /** 系统返回键监听（有就报，没有不报） */
  BackPress = 31025,
  /** 应用Hide */
  AppHide = 31027,
  /** 非法点击(action_value =页面名称 action_value2 =点击区域名称 app_x, app_y) */
  IllegalClick = 31028,
  /** 功能按钮点击(action_value =页面名称 action_value2 =点击名称) */
  BtnClick = 31029,
  /** 页面滑动次数上报(action_value =页面名称 action_value2 =页面ID) */
  PageTouchMoved = 31030,
  /** 全页面曝光 */
  PageShow = 31031,
  /** 应用到前台 */
  AppShow = 31032,
  /** 启动上报(action_value =启动次数,>1都是热启) */
  AppOeLaunch = 31033,
  /** 非首页曝光 */
  PageShowNotMain = 31035,
  /** 行为收集（仅做分析数据用） */
  ActionCollect = 31036,
  /** 付费行为 */
  PayAction = 31037,
  /** 互动页非关键区域点击上报 */
  NonCriticalAreaClick = 31038
}

/** 上报EVENT类型 */
export enum ReportEvent {
  SDK = "HaaApp",
  AppLaunch = "AppLaunch",
  QuickApp = "QuickApp",
  EnterPage = "EnterPage",
  ExitPage = "ExitPage",
  Exception = "Exception",
  ClickAction = "ClickAction",
  Helper = "Helper",
  Strategy = "AD_CONFIG",
  Ad = "AdAction"
}
