import { OeReportSubType, OeReportType } from "./enum";
import { useOeReport } from "./useOeReport";
// import { useReport } from "./useReport";

const { oeReport } = useOeReport();
// const { reportEnterPage } = useReport();

const router: { routerInstance: any; home: string | string[] } = {
  routerInstance: {},
  home: ""
};

const setRouter = (r: any, home: string | string[]) => {
  router.routerInstance = r;
  router.home = home;
  router.routerInstance.beforeEach((to: any, from: any, next: any) => {
    if (typeof router.home == "string") {
      if (to.name == router.home) {
        oeReport(OeReportType.Action, OeReportSubType.HomeShow);
      } else {
        oeReport(OeReportType.Action, OeReportSubType.PageShowNotMainOld, to.name);
        oeReport(OeReportType.Action, OeReportSubType.PageShowNotMain, to.name);
      }
    } else {
      if (router.home.some((item) => item == to.name)) {
        if (!router.home.some((item) => item == from.name)) {
          oeReport(OeReportType.Action, OeReportSubType.HomeShow);
        }
      } else {
        oeReport(OeReportType.Action, OeReportSubType.PageShowNotMainOld, to.name);
        oeReport(OeReportType.Action, OeReportSubType.PageShowNotMain, to.name);
      }
    }
    oeReport(OeReportType.Action, OeReportSubType.PageShow, to.name);
    // reportEnterPage(to.name);
    next();
  });
};

export function useRouter() {
  return { router, setRouter };
}
