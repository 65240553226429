export const getUrlParams = (url: string) => {
  url = url || window.location.toString();
  const paramStr = url.split("?")[1];
  if (!paramStr) return {};
  const kavs = paramStr.split("&");
  const result: AnyObject = {};
  kavs.forEach((item) => {
    const kv = item.split("=");
    result[kv[0]] = kv[1].replace("#/", "");
  });
  return result;
};
