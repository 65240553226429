/*
 * @Date: 2023-07-12 11:27:30
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { getUrlParams } from "@/utils/common";
const params: AnyObject = getUrlParams(window.location.href);
const getRedirect = () => {
  if (process.env.VUE_APP_START == "index") return "/main";
  else return "/main";
  if (process.env.VUE_APP_START == "index") return "/index";
  else return "/index";
};
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: getRedirect()
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("@/views/HomeView.vue")
  },
  {
    path: "/main",
    name: "Main",
    component: () => import("@/views/MainView.vue")
  },
  {
    path: "/marketing",
    name: "Marketing",
    component: () => import("@/views/marketing/MarketingView.vue")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    // 解决路由跳转后 会滚动到底部
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    // 异步滚动操作
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 });
      }, 0);
    });
  },
  routes
});

export default router;
