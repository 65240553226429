<!--
 * @Date: 2023-11-08 16:40:58
-->
<template>
  <router-view />
</template>

<style lang="less">
// ::-webkit-scrollbar {
//   width: 0 !important;
//   display: none;
// }
// ::-webkit-scrollbar {
//   width: 0 !important;
//   height: 0;
//   display: none;
// }
</style>
