/*
 * @Date: 2023-07-13 15:19:28
 */
// import directives
import { App } from "vue";
import copy from "@/directives/modules/copy";
import debounce from "@/directives/modules/debounce";

const directivesList: any = {
  copy,
  debounce
};

const directives = {
  install: function (app: App<Element>) {
    Object.keys(directivesList).forEach((key) => {
      // 注册自定义指令
      app.directive(key, directivesList[key]);
    });
  }
};

export default directives;
