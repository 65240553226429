/*
 * @Date: 2023-11-09 11:28:05
 */
import { onBeforeMount, onMounted, onBeforeUnmount, onErrorCaptured, watch } from "vue";
import { useOeReport } from "./useOeReport";
import { useVisible } from "./useVisible";

const { PageDeep, _calculatedPageCount } = useOeReport();
const { visible } = useVisible();

export function useLifeCycle() {
  const pageDeep = new PageDeep();

  /** 记录页面深度 */
  const deepCount = sessionStorage.getItem("HAA_PAGE_DEEP");
  const countNumber = deepCount ? +deepCount + 1 : _calculatedPageCount.count + 1;
  sessionStorage.setItem("HAA_PAGE_DEEP", countNumber + "");

  watch(
    () => visible.value,
    () => {
      if (visible.value) pageDeep.show();
      else pageDeep.hide();
    }
  );
  onBeforeMount(() => {
    pageDeep.init();
  });
  onMounted(() => {
    pageDeep.show();
  });
  onBeforeUnmount(() => {
    pageDeep.hide();
  });
  onErrorCaptured((error) => {
  });
}
